@font-face {
  font-family: "baskerville";
  src: url("fonts/Libre_Baskerville/LibreBaskerville-Regular.ttf")
    format("truetype");
}

@font-face {
  font-family: "garamond";
  src: url("fonts/Cormorant_Garamond/CormorantGaramond-Regular.ttf")
    format("truetype");
}

@font-face {
  font-family: "bodoni";
  src: url("fonts/Libre_Bodoni/LibreBodoni-VariableFont_wght.ttf")
    format("truetype");
}

@font-face {
  font-family: "courier";
  src: url("fonts/Courier_Prime/CourierPrime-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "georgia";
  src: url("fonts/Noto_Sans_Georgian/NotoSansGeorgian-VariableFont_wdth\,wght.ttf")
    format("truetype");
}

@font-face {
  font-family: "script";
  src: url("fonts/Kaushan_Script/KaushanScript-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Decorative";
  src: url("fonts/Cinzel_Decorative/CinzelDecorative-Regular.ttf")
    format("truetype");
}

@font-face {
  font-family: "AbrilFatface";
  src: url("fonts/Library_Fonts/Abril_Fatface/AbrilFatface-Regular.ttf")
    format("truetype");
}

@font-face {
  font-family: "Allura";
  src: url("fonts/Library_Fonts/Allura/Allura-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "AnonymousPro";
  src: url("fonts/Library_Fonts/Anonymous_Pro/AnonymousPro-Regular.ttf")
    format("truetype");
}

@font-face {
  font-family: "Anton";
  src: url("fonts/Library_Fonts/Anton/Anton-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Bangers";
  src: url("fonts/Library_Fonts/Bangers/Bangers-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Bitter";
  src: url("fonts/Library_Fonts/Bitter/Bitter-VariableFont_wght.ttf")
    format("truetype");
}

@font-face {
  font-family: "Cabin";
  src: url("fonts/Library_Fonts/Cabin/Cabin-VariableFont_wdth,wght.ttf")
    format("truetype");
}

@font-face {
  font-family: "Cardo";
  src: url("fonts/Library_Fonts/Cardo/Cardo-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "ComicNeue";
  src: url("fonts/Library_Fonts/Comic_Neue/ComicNeue-Regular.ttf")
    format("truetype");
}

@font-face {
  font-family: "CourierPrime";
  src: url("fonts/Library_Fonts/Courier_Prime/CourierPrime-Regular.ttf")
    format("truetype");
}

@font-face {
  font-family: "CrimsonText";
  src: url("fonts/Library_Fonts/Crimson_Text/CrimsonText-Regular.ttf")
    format("truetype");
}

@font-face {
  font-family: "DancingScript";
  src: url("fonts/Library_Fonts/Dancing_Script/static/DancingScript-Regular.ttf")
    format("truetype");
}

@font-face {
  font-family: "EBGaramond";
  src: url("fonts/Library_Fonts/EB_Garamond/static/EBGaramond-Medium.ttf")
    format("truetype");
}

@font-face {
  font-family: "GothicA1";
  src: url("fonts/Library_Fonts/Gothic_A1/GothicA1-Black.ttf")
    format("truetype");
}

@font-face {
  font-family: "GreatVibes";
  src: url("fonts/Library_Fonts/Great_Vibes/OFL.txt") format("truetype");
}

@font-face {
  font-family: "JosefinSans";
  src: url("fonts/Library_Fonts/Josefin_Sans/static/JosefinSans-Regular.ttf")
    format("truetype");
}

@font-face {
  font-family: "Lato";
  src: url("fonts/Library_Fonts/Lato/Lato-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Lora";
  src: url("fonts/Library_Fonts/Lora/static/Lora-Regular.ttf")
    format("truetype");
}

@font-face {
  font-family: "Merriweather";
  src: url("fonts/Library_Fonts/Merriweather/Merriweather-Regular.ttf")
    format("truetype");
}

@font-face {
  font-family: "MerriweatherSans";
  src: url("fonts/Library_Fonts/Merriweather_Sans/static/MerriweatherSans-Medium.ttf")
    format("truetype");
}

@font-face {
  font-family: "NotoSansJP";
  src: url("fonts/Library_Fonts/Noto_Sans_JP/static/NotoSansJP-Regular.ttf")
    format("truetype");
}

@font-face {
  font-family: "NunitoSans";
  src: url("fonts/Library_Fonts/Nunito_Sans/static/NunitoSans_10pt-Regular.ttf")
    format("truetype");
}

@font-face {
  font-family: "OpenSans";
  src: url("fonts/Library_Fonts/Open_Sans/static/OpenSans-Regular.ttf")
    format("truetype");
}

@font-face {
  font-family: "Oswald";
  src: url("fonts/Library_Fonts/Oswald/static/Oswald-Medium.ttf")
    format("truetype");
}

@font-face {
  font-family: "Pacifico";
  src: url("fonts/Library_Fonts/Pacifico/Pacifico-Regular.ttf")
    format("truetype");
}

@font-face {
  font-family: "PlayfairDisplay";
  src: url("fonts/Library_Fonts/Playfair_Display/static/PlayfairDisplay-Black.ttf")
    format("truetype");
}

@font-face {
  font-family: "PTSans";
  src: url("fonts/Library_Fonts/PT_Sans/PTSans-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Raleway";
  src: url("fonts/Library_Fonts/Raleway/static/Raleway-Black.ttf")
    format("truetype");
}

@font-face {
  font-family: "Roboto";
  src: url("fonts/Library_Fonts/Roboto/Roboto-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "UnifrakturMaguntia";
  src: url("fonts/Library_Fonts/UnifrakturMaguntia/UnifrakturMaguntia-Regular.ttf")
    format("truetype");
}

@font-face {
  font-family: "MarkPro";
  src: url("fonts/Mark_Pro.ttf") format("truetype");
}

/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', 'baskerville', serif , garamond , bodoni, georgia, script, Decorative, courier, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
} */

body {
  margin: 0;
  /* font-family: 'MarkPro', 'Abril Fatface', 'Allura', 'Anonymous Pro', 'Anton', 'Bangers', 'Bitter', 'Cabin', 'Cardo', 'Comic Neue', 'Courier Prime', 'Crimson Text', 'Dancing Script', 'EB Garamond', 'Gothic A1', 'Great Vibes', 'Josefin Sans', 'Lato', 'Lora', 'Merriweather', 'Merriweather Sans', 'Noto Sans JP', 'Nunito Sans', 'Open Sans', 'Oswald', 'Pacifico', 'Playfair Display', 'PT Sans', 'Raleway', 'Roboto', 'Unifraktur Maguntia', 'baskerville', 'garamond', 'bodoni', 'georgia', 'script', 'Decorative', 'courier', sans-serif , 'Satisfy', 'Inter', sans-serif , 'MarkPro' 'AbrilFatface', 'Allura', 'AnonymousPro', 'Anton', 'Bangers', 'Bitter', 'Cabin', 'Cardo', 'ComicNeue', 'CourierPrime', 'CrimsonText', 'DancingScript', 'EBGaramond', 'GothicA1', 'GreatVibes', 'JosefinSans', 'Lato', 'Lora', 'Merriweather', 'MerriweatherSans', 'NotoSansJP', 'NunitoSans', 'OpenSans', 'Oswald', 'Pacifico', 'PlayfairDisplay', 'PTSans', 'Raleway', 'Roboto', 'UnifrakturMaguntia', 'baskerville', 'garamond', 'bodoni', 'georgia', 'script', 'Decorative', 'courier',  'Latin Modern', 'Lucida', 'Minion', 'Optima', 'Palatino', 'Rockwell',  sans-serif; */

  /* First set of fonts */
  font-family: "MarkPro", "Abril Fatface", "Allura", "Anonymous Pro", "Anton",
    "Bangers", "Bitter", "Cabin", "Cardo", "Comic Neue", "Courier Prime",
    "Crimson Text", "Dancing Script", "EB Garamond", "Gothic A1", "Great Vibes",
    "Josefin Sans", "Lato", "Lora", "Merriweather", "Merriweather Sans",
    "Noto Sans JP", "Nunito Sans", "Open Sans", "Oswald", "Pacifico",
    "Playfair Display", "PT Sans", "Raleway", "Roboto", "Unifraktur Maguntia",
    "Baskerville", "Garamond", "Bodoni", "Georgia", "Script", "Decorative",
    "Courier", sans-serif;

  /* Second set of fonts */
  font-family: "Satisfy", "Inter", "MarkPro", "AbrilFatface", "Allura",
    "AnonymousPro", "Anton", "Bangers", "Bitter", "Cabin", "Cardo", "ComicNeue",
    "CourierPrime", "CrimsonText", "DancingScript", "EBGaramond", "GothicA1",
    "GreatVibes", "JosefinSans", "Lato", "Lora", "Merriweather",
    "MerriweatherSans", "NotoSansJP", "NunitoSans", "OpenSans", "Oswald",
    "Pacifico", "PlayfairDisplay", "PTSans", "Raleway", "Roboto",
    "UnifrakturMaguntia", "Baskerville", "Garamond", "Bodoni", "Georgia",
    "Script", "Decorative", "Courier", "Latin Modern", "Lucida", "Minion",
    "Optima", "Palatino", "Rockwell", sans-serif;

  font-family: "Lucida", sans-serif;
  font-family: "Minion", sans-serif;
  font-family: "Optima", sans-serif;
  font-family: "Palatino", sans-serif;
  font-family: "Rockwell", sans-serif;
  font-family: "Arial", sans-serif;
  font-family: "American Typewriter", serif;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.page-break {
  page-break-before: always;
}

.DraftEditor-root {
  font-size: inherit !important;
  line-height: normal !important;
}
